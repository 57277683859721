import { Card, Empty } from "antd";
import React from "react";

const NotFoundData = (props) => {
  const { message } = props;
  return (
    <>
      <Card>
        <Empty className="text-[15px]" description={<>{message}</>} />
      </Card>
    </>
  );
};

export default NotFoundData;

import { Button, Card, Checkbox, Col, Divider, Form, Input, Radio, Row, Space, Typography } from "antd";
import { useState } from "react";

import './styles/consent.css';

function Settings() {
    const [payment_method, setPaymentMethod] = useState();

    const changePaymentMethod = (e) => {
        setPaymentMethod(e.target.value);
    }
    return <Card className="bg-light">
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} colon={false} onFinish={ console.log } layout={'horizontal'}>
            <Space direction="vertical">
                <Typography.Title level={5}>IN THE EVENT OF A PURCHASE, I DECLARE:</Typography.Title>
                <Form.Item labelCol={{ span: 0 }} label={null} name={'declaration'} rules={[{ required: true }]}>
                    <div><Checkbox defaultChecked={false}>To purchase the work as the principal owner</Checkbox></div>
                    <div><Checkbox defaultChecked={false}>To have the authority to purchase the work</Checkbox></div>
                    <div><Checkbox defaultChecked={false}>To purchase this work as the sole Ultimate Beneficial Owner (UBO)</Checkbox></div>
                </Form.Item>
                <Typography.Text>Confirm with your e-signature</Typography.Text>
                <Form.Item wrapperCol={{ xs: 24, md: 12 }} label={null} name={'signature'} rules={[{ required: true }]}>
                    <Input placeholder="/s/YOUR_NAME"/>
                </Form.Item>
                <Typography.Title level={5}>Preferred Payment Method</Typography.Title>
                <Form.Item labelCol={{ span: 0 }} label={null} name={'payment_method'} rules={[{ required: true }]}>
                    <Radio.Group onChange={ changePaymentMethod } name="payment_method">
                        <Radio value={ 'bank' }>Bank Transfer</Radio>
                        <Radio value={ 'card' }>Credit Card</Radio>
                    </Radio.Group>
                </Form.Item>
            </Space>
            { payment_method === 'bank' && <><Form.Item label="Bank Name"><Input placeholder="Name of the Bank" /></Form.Item>
            <Form.Item label="Account Number"><Input placeholder="Account Number" /></Form.Item>
            <Form.Item label="(Street + Nr)"><Input placeholder="(Street + Nr)" /></Form.Item>
            <Form.Item label="Address">
                <Row gutter={[8, 8]}>
                    <Col flex={ 'auto'}><Input placeholder="City" /></Col>
                    <Col flex={ 'auto'}><Input placeholder="Zip Code" /></Col>
                    <Col flex={ 'auto'}><Input placeholder="Country" /></Col>
                </Row>
            </Form.Item></> }
            { payment_method === 'card' && <><Form.Item label="Name of the Card Issuer"><Input placeholder="Name of the Card Issuer" /></Form.Item>
            <Form.Item label="Card Number"><Input placeholder="Card Number" /></Form.Item>
            <Form.Item label="Name on the Card"><Input placeholder="Name on the Card" /></Form.Item>
            <Form.Item label="Expiry Date">
                <Row gutter={[8, 8]}>
                    <Col flex={ 'auto'}><Input placeholder="MM" /></Col>
                    <Col flex={ 'auto'}><Input placeholder="YYYY" /></Col>
                </Row>
            </Form.Item></> }
            <Divider />
            <Row justify="end">
                <Button type="primary" htmlType="submit">UPDATE</Button>
            </Row>
        </Form>
    </Card>;
}

export default Settings;
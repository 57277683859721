import { useEffect } from "react";

function Logout() {
  useEffect(() => {
    localStorage.clear();
    localStorage.removeItem("userId");
    window.location.href = "/";
  }, []);

  return;
}

export default Logout;

import React, { useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { baseUrl } from "../services/services.profile";
import { FaUserAlt } from "react-icons/fa";
import logoImg from "../assets/images/logo_black_on_white.png";
import axios from "axios";
import { message } from "antd";

const RequestInfoModal = ({ setRequesModal, requesModal }) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const forgotPassword = async (email, fname, lname, txtExplain) => {
    const res = await axios
      .post(baseUrl + "/api/auth/infodeck", {
        email,
        fname,
        lname,
        txtExplain,
      })
      .then((res) => {
        message.success(res?.data?.message);
      })
      .catch((err) => {
        message.error(res?.data?.message);
      });
  };

  return (
    <>
      {requesModal ? (
        <>
          <Formik
            initialValues={{
              fname: "",
              lname: "",
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("First name is Required")
                .required("Please enter your email"),

              fname: Yup.string().required("First name is required"),
              lname: Yup.string().required("Last name is required"),
            })}
            onSubmit={(values) => {
              let email = values.email;
              let fname = values.fname;
              let lname = values.lname;
              let txtExplain = values.txtExplain;
              forgotPassword(email, lname, fname, txtExplain);
            }}
          >
            {({ handleSubmit, handleChange, handleBlur }) => (
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40"
                  onClick={() => setRequesModal(false)}
                ></div>
                <div className="flex flex-col justify-center items-center min-h-screen bg-[#00000073] py-8">
                  <div className="relative w-full max-w-[30rem] mx-auto bg-white rounded-md shadow-lg">
                    <div className="flex justify-center items-center rounded-t-md text-white font-bold p-6">
                      <img src={logoImg} alt="" className=" w-[200px] " />
                    </div>
                    <div className="w-full flex items-center  px-8 pt-4 pb-10 text-base ">
                      <form onSubmit={handleSubmit}>
                        <div className="flex justify-between items-center">
                          <div>Request the artpass ID info deck</div>
                        </div>

                        {/* first name  */}
                        <div className="mb-4">
                          <div className="my-[10px] flex justify-center border-none text outline-none items-center !mb-[6px] bg-[#00000013]">
                            <div className="bg-[#e9ecef] border border-solid border-[#ced4da] p-3">
                              <FaUserAlt />
                            </div>
                            <input
                              type="fname"
                              name="fname"
                              id="fname"
                              placeholder="First Name"
                              className="border-none outline-none focus:outline-none "
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="fname"
                            className="text-red-500 text-sm"
                          />
                        </div>

                        {/* last name  */}
                        <div className="mb-4">
                          <div className="my-[10px] flex justify-center border-none text outline-none items-center !mb-[6px] bg-[#00000013]">
                            <div className="bg-[#e9ecef] border border-solid border-[#ced4da] p-3">
                              <FaUserAlt />
                            </div>
                            <input
                              type="lname"
                              name="lname"
                              id="lname"
                              placeholder="Last Name"
                              className="border-none outline-none focus:outline-none "
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="lname"
                            className="text-red-500 text-sm"
                          />
                        </div>

                        {/* last name  */}
                        <div className="mb-4">
                          <div className="my-[10px] flex justify-center border-none text outline-none items-center !mb-[6px] bg-[#00000013]">
                            <div className="bg-[#e9ecef] border border-solid border-[#ced4da] p-3">
                              <FaUserAlt />
                            </div>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Enter business email"
                              className="border-none outline-none focus:outline-none "
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="email"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="mb-4">
                          <div className="flex justify-center border-[#00000040] border border-solid items-center !mb-[6px]">
                            <textarea
                              type="txtExplain"
                              name="txtExplain"
                              id="txtExplain"
                              placeholder="Please explain in a few words the main purpose of your request."
                              className="border-none outline-none focus:outline-none "
                              onBlur={handleBlur}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                          {/* <ErrorMessage
                            component="div"
                            name="txtExplain"
                            className="text-red-500 text-sm"
                          /> */}
                        </div>

                        <button
                          type="submit"
                          className="text-lg  border border-solid border-[#000] h-12 px-5 hover:text-white hover:bg-[#000]"
                        >
                          REQUEST
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default RequestInfoModal;

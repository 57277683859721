import React from "react";
import { useState } from "react";
import {
  Select,
  Row,
  Col,
  Space,
  Divider,
  Button,
  Typography,
  Input,
} from "antd";
import axios from "axios";
import { message } from "antd";
import { baseUrl } from "../services/services.profile";

const countryCodes = [
  { name: "Albania (+355)", code: "355", iso2code: "ALB" },
  { name: "Algeria (+213)", code: "213", iso2code: "DZA" },
  { name: "American Samoa (+684)", code: "684", iso2code: "ASM" },
  { name: "Andorra (+376)", code: "376", iso2code: "AND" },
  { name: "Angola (+244)", code: "244", iso2code: "AGO" },
  { name: "Argentina (+54)", code: "54" },
  { name: "Armenia (+374)", code: "374" },
  { name: "Aruba (+297)", code: "297" },
  { name: "Australia (+61)", code: "61", iso2code: "AUS" },
  { name: "Austria (+43)", code: "43", iso2code: "AUT" },
  { name: "Azerbaijan (+994)", code: "994" },
  { name: "Bangladesh (+880)", code: "+880" },
  { name: "Belarus (+375)", code: "375" },
  { name: "Belgium (+32)", code: "32", iso2code: "BEL" },
  { name: "Bolivia (+591)", code: "591" },
  { name: "Bosnia and Herzegovina (+387)", code: "387" },
  { name: "Botswana (+267)", code: "267" },
  { name: "Brazil (+55)", code: "55" },
  { name: "Bulgaria (+359)", code: "359" },
  { name: "Cambodia (+855)", code: "855" },
  { name: "Cameroon (+237)", code: "237" },
  { name: "Canada (+1)", code: "1", iso2code: "CAN" },
  { name: "Chile (+56)", code: "56" },
  { name: "China (+86)", code: "86" },
  { name: "Colombia (+57)", code: "57" },
  { name: "Costa Rica (+506)", code: "506" },
  { name: "Croatia (+38)", code: "38" },
  { name: "Cuba (+53)", code: "53" },
  { name: "Czech Republic (+420)", code: "420" },
  { name: "Denmark (+45)", code: "45", iso2code: "DNK" },
  { name: "Ecuador (+593)", code: "593" },
  { name: "Egypt (+20)", code: "20" },
  { name: "El Salvador (+503)", code: "503" },
  { name: "Estonia (+372)", code: "372" },
  { name: "Ethiopia (+251)", code: "251" },
  { name: "Finland (+358)", code: "358", iso2code: "FIN" },
  { name: "France (+33)", code: "33", iso2code: "FRA" },
  { name: "Germany (+49)", code: "49", iso2code: "DEU" },
  { name: "Greece (+30)", code: "30" },
  { name: "Hungary (+36)", code: "36" },
  { name: "Iceland (+354)", code: "354", iso2code: "ISL" },
  { name: "India (+91)", code: "91" },
  { name: "Indonesia (+62)", code: "62" },
  { name: "Italy (+39)", code: "39" },
  { name: "Japan (+81)", code: "81" },
  { name: "Mexico (+52)", code: "52" },
  { name: "Netherlands (+31)", code: "31", iso2code: "NLD" },
  { name: "New Zealand (+64)", code: "64", iso2code: "NZL" },
  { name: "Poland (+48)", code: "48" },
  { name: "Romania (+40)", code: "40" },
  { name: "Switzerland (+41)", code: "41" },
  { name: "United Kingdom (+44)", code: "44", iso2code: "GBR" },
  { name: "United States of America (+1)", code: "1", iso2code: "USA" },
  { name: "Venezuela (+58)", code: "58" },
  { name: "Vietnam (+84)", code: "84", iso2code: "VNM" },
];

function PrivateOnBoarding(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState({});

  const { Option } = Select;

  const validation = () => {
    if (firstName === "") {
      message.warning("Please enter your first name");
      return false;
    }
    if (lastName === "") {
      message.warning("Please enter your last name");
      return false;
    }
    if (email === "") {
      message.warning("Please enter your email");
      return false;
    }
    //email validation regex
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      message.warning("Please enter a valid email");
      return false;
    }
    if (phone === "") {
      message.warning("Please enter your phone number");
      return false;
    }
    //phone validation regex
    if (!/^\d{10}$/.test(phone)) {
      message.warning("Please enter a valid phone number");
      return false;
    }
    if (phoneCode === "") {
      message.warning("Please select your country code");
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (validation()) {
      axios
        .post(baseUrl+"/api/onboarding/create-private", {
          fname: firstName,
          lname: lastName,
          email: email,
          phone_number: phone,
          phone_code: phoneCode,
        })
        .then((res) => {
          message.success("Success, a request has been sent to your email");
          //   Go to request idv and cdd page after 2 seconds
          setTimeout(() => {
            // window.location.href = "/request-idv-and-cdd";
            props.onBack();
          }, 2000);
        })
        .catch((err) => {
          console.log("Err : ", err);
        });
    }
  };

  return (
    <Space direction="vertical" size="small">
      {/* Buttons */}
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button level={5} onClick={props.onBack}>
            Back
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            level={5}
            onClick={() => {
              onSubmit();
            }}
          >
            Confirm
          </Button>
        </div>
      </Row>
      <Divider dashed />
      {/* FIRST NAME */}
      <Row gutter={[16, 16]} align="middle">
        <Col span={4}>
          <Typography.Text>First Name</Typography.Text>
        </Col>
        <Col span={8} flex={"auto"}>
          <Input
            onChange={(e) => setLastName(e.target.value)}
            size="medium"
            placeholder="Enter first name"
          />
        </Col>
      </Row>
      <Divider dashed />
      {/* LAST NAME */}
      <Row gutter={[16, 16]} align="middle">
        <Col span={4}>
          <Typography.Text>Last Name</Typography.Text>
        </Col>
        <Col span={8} flex={"auto"}>
          <Input
            onChange={(e) => setFirstName(e.target.value)}
            size="medium"
            placeholder="Enter last name"
          />
        </Col>
      </Row>
      <Divider dashed />
      {/* EMAIL */}
      <Row gutter={[16, 16]} align="middle">
        <Col span={4}>
          <Typography.Text>Email ID</Typography.Text>
        </Col>
        <Col span={8} flex={"auto"}>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            size="medium"
            placeholder="Enter email ID"
          />
        </Col>
      </Row>
      <Divider dashed />
      {/* COUNTRY CODES */}
      <Row gutter={[16, 16]} align="middle">
        <Col span={4}>
          <Typography.Text>Country Code</Typography.Text>
        </Col>
        <Col span={8} flex={"auto"}>
          <Select
            placeholder="Select country code"
            style={{ width: "100%", border: "2px solid black" }}
            showSearch
            onChange={(v) => {
              setPhoneCode(JSON.parse(v));
            }}
          >
            {countryCodes.map((country, i) => (
              <Option
                size="medium"
                value={JSON.stringify(country)}
                key={country.code}
                width="100%"
              >
                {country.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider dashed />
      {/* PHONE NUMBER */}
      <Row gutter={[16, 16]} align="middle">
        <Col span={4}>
          <Typography.Text>Phone Number</Typography.Text>
        </Col>
        <Col span={8} flex={"auto"}>
          <Input
            onChange={(e) => setPhone(e.target.value)}
            size="medium"
            placeholder="Enter phone number"
          />
        </Col>
      </Row>
      <Divider dashed />
    </Space>
  );
}

export default PrivateOnBoarding;

/* eslint-disable no-unused-vars */
import { Button, Card, Col, Row, Typography } from "antd";
import { useState } from "react";
import PrivateOnBoarding from "./PrivateOnBoarding";
import PublicOnBoarding from "./PublicOnBoarding";

function RequestIDVCDD() {
  const [isPrivateOnboarding, setPrivateOnboarding] = useState(false);
  const [isPublicOnboarding, setPublicOnboarding] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      {!isPrivateOnboarding && !isPublicOnboarding && (
        <>
          {/* <div className="bg-[#f0f6fd] mb-4 p-6 flex text-[15px] justify-between items-center">
            <p>Private Onboarding Link</p>
            <button
              className="bg-primary text-white h-9 px-3 py-[6px] rounded-lg"
              onClick={() => {
                setPrivateOnboarding(true);
              }}
            >
              Create
            </button>
          </div> */}
          <div className="bg-[#f0f6fd] mb-4 p-6 text-[15px] flex justify-between items-center">
            <p>Public Onboarding Link</p>
            <button
              className="bg-primary text-white h-9 px-3 py-[6px] rounded-lg"
              onClick={() => {
                setPublicOnboarding(true);
              }}
            >
              Create
            </button>
          </div>
        </>
      )}

      {isPrivateOnboarding && (
        <PrivateOnBoarding
          onBack={() => {
            setPrivateOnboarding(false);
          }}
        />
      )}

      {isPublicOnboarding && (
        <PublicOnBoarding
          onBack={() => {
            setPublicOnboarding(false);
          }}
        />
      )}
    </>
  );
}

export default RequestIDVCDD;
